export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {
        getItems: (state) => state.items,
    },
    mutations: {
        setItems: (state, items) => state.items = items
    },
    actions: {
        load({commit}) {
            commit('setItems', [

            ])
        },
    }
}