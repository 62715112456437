import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
    {
        path: '/dashboard/welcome',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "dashboard-welcome" */ '../views/dashboard/Welcome.vue')
    },
    {
        path: '/dashboard/projects',
        name: 'projects',
        component: () => import(/* webpackChunkName: "dashboard-projects" */ '../views/dashboard/Projects.vue')
    },
    {
        path: '/dashboard/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "dashboard-settings" */ '../views/dashboard/Settings.vue')
    },
    {
        path: '/dashboard/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "dashboard-profile" */ '../views/dashboard/Profile.vue')
    },
    {
        path: '/dashboard/issues',
        name: 'issues',
        component: () => import(/* webpackChunkName: "dashboard-issues" */ '../views/dashboard/Issues.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "dashboard-login" */ '../views/Login.vue')
    },
    {
        path: '*',
        redirect: { name: 'welcome' }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
