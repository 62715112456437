import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        themes: {
            light: {
                primary: '#007BC4',
                navigation: '#F5F5F5',
            },
            dark: {
                primary: '#007BC4',
                navigation: '#F5F5F5',
            },
        },
    },
});
