import sidebar from './navigation/sidebar.module'
import toolbar from './navigation/toolbar.module'

export default {
    namespaced: true,
    actions: {
        load({dispatch}) {
           return Promise.all([
               dispatch('sidebar/load'),
               dispatch('toolbar/load'),
           ])
        },
    },
    modules: {
        sidebar,
        toolbar
    }
}
