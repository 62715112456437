<template>
    <v-navigation-drawer
        app
        :permanent="!$vuetify.breakpoint.mobile"
        :temporary="$vuetify.breakpoint.mobile"
        color="navigation"
        clipped
        v-model="drawer"
        :mini-variant="isMini"
    >
        <v-list
            dense
            nav
        >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="item.route"
                color="primary"
                :disabled="item.disabled"
            >
                <v-tooltip bottom v-if="isMini" color="primary">
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                    </template>
                    <span>{{ item.label }}</span>
                </v-tooltip>

                <v-list-item-icon v-else>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'AtNavigation',

    computed: {
        items() {
            return this.$store.getters["navigation/sidebar/getItems"]
        },
        isMini() {
            return !this.$vuetify.breakpoint.mobile && this.$store.getters['navigation/sidebar/isMini']
        },
        drawer: {
            get() {
                return this.$store.getters["navigation/sidebar/getValue"]
            },
            set(value) {
                this.$store.commit('navigation/sidebar/setValue', value)
            }
        }
    }
}
</script>