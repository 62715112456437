import { faker } from '@faker-js/faker';

import sidebar from './navigation/sidebar.module'
import toolbar from './navigation/toolbar.module'

const getRandomShopwareVersion = () => {
    const major = Math.ceil(Math.random() * 2) + 4;
    const minor = Math.floor(Math.random() * 10);
    const patch = Math.floor(Math.random() * 10);

    return `${major}.${minor}.${patch}.`;
}

const getRandomPhpVersion = () => {
    const major = Math.ceil(Math.random() * 2) + 6;
    const minor = Math.floor(Math.random() * 10);
    const patch = Math.floor(Math.random() * 10);

    return Math.random() < 0.5
        ? `${major}.${minor}.`
        : `${major}.${minor}.${patch}`;
}

export default {
    namespaced: true,
    state: {
        projects: [],
    },
    getters: {
        getProjects: (state) => state.projects
    },
    mutations: {
        setProjects: (state, projects) => state.projects = projects,
    },
    actions: {
        load({commit}) {
            commit('setProjects', Array.apply(null, Array(20)).map((_, index) => {
                return {
                    id: index + 1,
                    customer: faker.company.companyName(),
                    domain: faker.internet.url(),
                    shopwareVersion: getRandomShopwareVersion(),
                    phpVersion: getRandomPhpVersion(),
                    pNumber: Math.random() < 0.6 ? `p${Math.ceil(Math.random() * 899999) + 100000}` : '',
                    hoster: ['Mittwald', 'Hetzner', 'selbst'][Math.floor(Math.random() * 3)],
                    assignedEmployee: ['AVH', 'AG'][Math.floor(Math.random() * 2)],
                    state: ['erledigt', 'ausstehend'][Math.floor(Math.random() * 2)],
                    securityUpdateOverPlugin: [true, false][Math.floor(Math.random() * 2)],
                }
            }))
        },
    },
    modules: {
        sidebar,
        toolbar
    }
}