import Vue from 'vue'
import Vuex from 'vuex'

import navigation from './modules/navigation.module'
import projects from './modules/projects.module'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        appTitle: 'Wartungsliste',
        appSubTitle: 'Shopware'
    },
    getters: {
        getAppTitle: (state) => state.appTitle,
        getAppSubTitle: (state) => state.appSubTitle,
    },
    mutations: {
        setAppTitle: (state, appTitle) => state.appTitle = appTitle,
        setAppSubTitle: (state, appSubTitle) => state.appSubTitle = appSubTitle,
    },
    actions: {

    },
    modules: {
        navigation,
        projects
    }
})
