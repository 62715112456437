<template>
    <v-app-bar app color="primary" clipped-left dark>
        <v-app-bar-nav-icon @click.stop="toggleNavigation"/>

        <v-app-bar-title class="text-no-wrap">
            {{ appTitle }}
        </v-app-bar-title>

        <v-spacer/>
    </v-app-bar>
</template>

<script>
export default {
    name: 'AtToolbar',

    computed: {
        appTitle() {
            return this.$store.getters.getAppTitle
        },
    },

    methods: {
        toggleNavigation() {
            this.$store.commit('navigation/sidebar/toggleDrawer', this.$vuetify)
        }
    }
};
</script>