<template>
    <v-app>
        <at-toolbar/>
        <at-navigation/>

        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
import AtNavigation from "@/components/AtNavigation";
import AtToolbar from "@/components/AtToolbar";
export default {
    name: 'App',

    components: {
        AtNavigation,
        AtToolbar
    },

    data: () => ({
        loaded: false,
        error: null
    }),

    mounted() {
        Promise.all([
            this.$store.dispatch("navigation/load"),
            this.$store.dispatch("projects/load"),
        ]).then(() => {
            this.loaded = true
        }).catch((error) => {
            this.error = error
        })
    }
};
</script>