export default {
    namespaced: true,
    state: {
        items: [],
        value: false,
        mini: false,
    },
    getters: {
        getItems: (state) => state.items,
        getValue: (state) => state.value,
        isMini: (state) => state.mini,
    },
    mutations: {
        setItems: (state, items) => state.items = items,
        setValue: (state, value) => state.value = value,
        toggleDrawer: (state, vuetify) => {
            if (vuetify.breakpoint.mobile) {
                state.value = !state.value;
            } else {
                state.mini = !state.mini;
            }
        },
    },
    actions: {
        load({commit}) {
            commit('setItems', [
                {
                    label: 'Dashboard',
                    icon: 'home',
                    route: { name: 'welcome' }
                },
                {
                    label: 'Projects',
                    icon: 'fact_check',
                    route: { name: 'projects' }
                },
                {
                    label: 'Settings',
                    icon: 'settings',
                    route: { name: 'settings' },
                    disabled: false
                },
                {
                    label: 'Profile',
                    icon: 'manage_accounts',
                    route: { name: 'profile' },
                    disabled: false
                },
                {
                    label: 'Issues',
                    icon: 'receipt_long',
                    route: { name: 'issues' },
                    disabled: false
                },
            ])
        },
    }
}